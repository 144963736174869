.orderTable {
	border: 1px solid #ccc;
	border-collapse: collapse;
	margin: 0;
	padding: 0;
	width: 100%;
	table-layout: fixed;
}

.orderTable caption {
	font-size: 1.5em;
	margin: .5em 0 .75em;
}
.orderTable .orderTr {
	background-color: #fff;
	border: 1px solid #e1e5e9;
	padding: .35em;
	border-radius: 3px;
}
.orderTable .orderTablehead .orderTr:first-child {
    border: 1px solid #0f82e6;
}
.orderTable .orderTh,
.orderTable .ordertTd {
	padding: 1.625em;
	text-align: center;
	color: #9da9b9;
	font-size: 14px;
	font-family: cursive;
}

.orderTable .ordertTd:nth-child(4) {
	font-size: 18px;
}

.orderTable .orderTh {
	font-size: .85em;
	letter-spacing: .1em;
	text-transform: uppercase;
	background: #0f82e6;
	color: #FFF;
}

.orderTable tbody .orderTr .ordertTd .btn-invoice {
	background: #0f82e6;
	color: #FFF;
	font-size: 13px;
	padding: 10px 20px;
	border: 0;
	font-family: cursive;
}

tbody .orderTr:nth-child(even) {
	background-color: #eee;
}

tbody .orderTr:nth-child(odd) {
	background-color: #fff;
}

@media screen and (max-width: 600px) {
	.orderTable {
		border: 0;
	}
	.orderTable caption {
		font-size: 1.3em;
	}
	.orderTable .orderTablehead {
		border: none;
		clip: rect(0 0 0 0);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		position: absolute;
		width: 1px;
		padding: 0;
	}
	.orderTable .orderTr {
		border-bottom: 3px solid #e1e5e9;
		display: block;
		margin-bottom: .625em;
	}
	.orderTable .orderTh,
	.orderTable .ordertTd {
		padding: 0.625em;
	}
	.orderTable .ordertTd {
		border-bottom: 1px solid #e1e5e9;
		display: block;
		font-size: .8em;
		text-align: right;
		color: #9da9b9;
	}
	.orderTable .ordertTd::before {
		content: attr(data-label);
		float: left;
		font-weight: bold;
		text-transform: uppercase;
		color: #656971;
	}
	.orderTable .ordertTd:last-child {
		border-bottom: 0;
	}
	.orderTable .ordertTd:nth-child(4) {
		font-size: 0.8em;
	}
}