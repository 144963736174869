
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

.forminp{
  width: 100%;
  padding: 10px;
  outline: none;
  border: none;
  border-radius: 0;
  border: 1px solid #B4B4B3;
  /* color: aquamarine; */
}
.formbtn{
  border-radius: 0;
  outline: none;
  color: #fff;
  background-color: black;
  padding-left: 20px;
  padding-right: 20px;
}

.heading{
  display: inline-block;
  padding: 5px;
  font-size: x-large;
  border-bottom: 2px solid black;


}

.formleb{
  padding: 7px;
  /* padding-top: 20px; */
  padding-left: 0;
  display: inline-block;
}
.backgroundimgcol,.form{

  /* background-image: linear-gradient(rgba(0, 0, 0, 0.3),rgba(0, 0, 0, 0.3)),url('https://blog-frontend.envato.com/cdn-cgi/image/width=1200,quality=85,format=auto/uploads/2022/04/E-commerce-App-JPG-File-scaled.jpg'); */
  background-image: linear-gradient(rgba(254, 254, 254, 0.49),rgba(255, 255, 255, 0.485));

  background-size: cover;
  background-repeat: no-repeat;
  
  /* width:auto; */
  /* height: auto; */
  padding: 30px;
}
.signbox{
  background-image: linear-gradient(rgba(0, 0, 0, 0.51),rgba(0, 0, 0, 0.614)),url('https://blog-frontend.envato.com/cdn-cgi/image/width=1200,quality=85,format=auto/uploads/2022/04/E-commerce-App-JPG-File-scaled.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  width:100%;
  height: 100vh;
  /* height: 100%; */
  /* height: 100vh; */
}
.captcha{
  font-weight: 700;
}
/* .taskdiv{
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  background-color: #fff;;

} */
.taskdiv{
  background-color: #0f1e3d;
  color:#fff;
}
.taskdiv1{
  background-color: #05afe8;
  color:#fff;
}
.taskdiv2{
  background-color: #e80532;
  color:#fff;
}
.taskdiv3{
  background-color:#05e8bb ;
  color:#fff;
}

input.largerCheckbox { 
  width: 40px; 
  height: 40px; 
} 
@media print {
  .aaaa {
      background-color: red;
      height: 100%;
      width: 100%;
      position: fixed;
      z-index:99;
      top: 0;
      left: 0;
      margin: 0;
      right:0;
      padding: 15px;
      font-size: 14px;
      line-height: 18px;
  }
}
body {
font-family:'Montserrat', sans-serif;
font-weight: 400;
color: #000;
}
header.top-bar {
h1 {
  font-family:'Montserrat', sans-serif;
}
}
main {
margin-top: 4rem;
min-height: calc(100vh - 107px);
.inner-container {
  max-width: 800px;
  margin: 0 auto;
}
}

table.invoice {
background: #fff;
.num {
  font-weight: 200;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: .8em;
}
tr, td {
  background: #fff;
  text-align: left;
  font-weight: 400;
  color: #000;
}
tr {
  &.header {
    td {
      img {
        max-width: 300px;
      }
      h2 {
        text-align: right;
        font-family: 'Montserrat', sans-serif;
        font-weight: 200;
        font-size: 2rem;
        color: #c8c3be;
      }
    }
  }
  &.intro {
    td {
      &:nth-child(2) {
        text-align: right;
      }
    }
  }
  &.details {
    > td { 
      padding-top: 4rem; 
      padding-bottom: 0; 
    }
    td, th {
      &.id,
      &.qty {
        text-align: center;
      }
      &:last-child {
        text-align: right;
      }
    }
    table {
      thead, tbody {
        position: relative;
        &:after {
          content: '';
          height: 1px;
          position: absolute;
          width: 100%;
          left: 0;
          margin-top: -1px;
          background: #c8c3be;
        }
      }
    }
  }
  &.totals {
    td {
      padding-top: 0;
    }
    table {
      tr {
        td {
          padding-top:0;
          padding-bottom:0;
          &:nth-child(1) {
            font-weight: 500;
          }
          &:nth-child(2) {
            text-align: right;
            font-weight: 200;
          }
        }
        &:nth-last-child(2) {
          
          td {
            padding-bottom: .5em;
            &:last-child {
              position: relative;
              &:after {
                content: '';
                height: 4px;
                width: 110%;
                border-top: 1px solid #1779ba;
                border-bottom: 1px solid #1779ba;
                position: relative;
                right: 0;
                bottom: -.575rem;
                display: block;
              }
            }
          }
          
        }
        &.total {
          td {
            font-size: 1.4em;
            padding-top: .5em;
            font-weight: 700;
            &:last-child {
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}
}

.additional-info {
h5 {
  font-size: 1.8em;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  /* color: $primary-color; */
}
}

@media print{
  body *{
    visibility: hidden;
  }
  .printme, .printme *{
    visibility: visible;
  }
 
}